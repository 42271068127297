let throttle = function (func, delay) {
    let timer = null;
    return function(){
      if (!timer) {
        timer = setTimeout(() => {
          func.apply(this, arguments);
          timer = null;
        }, delay);
      }
    };
};
let handle = () => {
    let scrollTop = $(document).scrollTop();
    if(!$('.page-head').hasClass('fixed')) {
        if (scrollTop > 50) {
            $('.page-head').addClass('fixed');
        } 
    } else {
        if (scrollTop < 50) {
            $('.page-head').removeClass('fixed');
        } 
    }
}
 $(document).scroll(throttle(handle,100));
